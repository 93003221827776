.dragContainer:hover {
  cursor: grab;
}

.dragContainer:active {
  cursor: grabbing;
}

.container {
  position: relative;
}

.button {
  position: absolute;
  top: 112px;
  width: 64px;
  height: 64px;
  background: var(--color-brand-dark-teal);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
}

.button.right {
  left: unset;
  right: 0;
  transform: translate(50%, -50%);
}

.button svg {
  fill: var(--color-brand-light-teal);
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 1500px) {
  .button {
    width: 48px;
    height: 48px;
  }

  .button svg {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 500px) {
  .button {
    top: 84.375px;
  }
}
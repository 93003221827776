.hvaErPcos {
  background: var(--color-brand-dark-teal);
  width: 100%;
  display: flex;
}

.textContainer {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
}

.textContainer * {
  color: var(--color-brand-light-teal);
}

.imageContainer {
  flex: 1;
  max-width: 50%;
  position: relative;
  width: 100%;
  height: 100%;
}

.imageContainer img {
  object-fit: cover;
}

@media only screen and (max-width: 750px) {
  .textContainer {
    padding: 32px;
  }
}

@media only screen and (max-width: 1000px) {
  .hvaErPcos {
    flex-direction: column-reverse;
  }

  .textContainer {
    max-width: unset;
  }

  .imageContainer {
    min-height: 400px;
    max-width: unset;
  }
}

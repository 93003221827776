.container {
  --container-horizontal-padding: var(--padding-horizontal);
  --hero-text-size: var(--font-size-title-large);
  position: relative;
  background: var(--color-brand-teal);
  padding: 2rem var(--container-horizontal-padding);
  height: max-content;
  flex-direction: column;
  gap: 4rem;
}

.container h1 {
  color: var(--color-brand-light-teal);
}

.container a {
  color: var(--color-brand-light-teal);
}

.container a:before {
  background: var(--color-brand-light-teal);
}

.text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 900px;
  padding: 1rem;
}

.text h1 {
  font-size: var(--hero-text-size);
}

.illustration {
  display: grid;
  grid-template-columns: repeat(10, min-content);
}

.illustration svg {
  width: 64px;
  height: 64px;
  max-width: calc((100vw - (var(--container-horizontal-padding) * 2)) / 10);
  max-height: calc((100vw - (var(--container-horizontal-padding) * 2)) / 10);
}

@media (min-width: 1250px) {
  .container {
    --container-horizontal-padding: calc(var(--padding-horizontal) + 4rem);
    height: calc(100vh - var(--header-height));
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .illustration svg {
    width: 48px;
    height: 48px;
  }
}

@media (min-width: 1500px) {
  .illustration svg {
    width: 64px;
    height: 64px;
  }
}
.articles {
  --gap-between-cards: 40px;
  --card-width: calc((100% - (var(--gap-between-cards) * 2)) / 3);
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 64px;
  min-width: 300px;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cards::-webkit-scrollbar {
  display: none;
}

.cards {
  display: flex;
  gap: var(--gap-between-cards);
  width: 100cqw;
  max-width: calc(100cqw - var(--padding-horizontal) * 2);
  overflow-x: scroll;
  -ms-overflow-style: none;
}

.card {
  --width: var(--card-width);
  width: var(--width);
  min-width: var(--width);
}

@media only screen and (max-width: 1500px) {
  .articles {
    gap: 48px;
  }

  .card {
    min-width: 400px;
  }
}

@media only screen and (max-width: 750px) {
  .articles {
    gap: 32px;
  }

  .cards {
    gap: 2rem;
  }
}

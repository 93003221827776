.omOss {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.textContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: var(--block-l)
}

.imageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.imageContainer > div {
  position: relative;
}

.imageContainer > div:first-of-type {
  width: 45%;
  aspect-ratio: 1067 / 1600;
}

.imageContainer > div:last-of-type {
  width: 80%;
  align-self: flex-end;
  aspect-ratio: 1736 / 1159;
  margin-top: -25%;
}

@media (min-width: 1000px) {
  .omOss {
    flex-direction: row;
    align-items: center;
    gap: var(--block-m);
  }

  .textContainer {
    margin-bottom: unset;
  }
}
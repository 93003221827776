.article {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.1s ease;
  min-width: 300px;
}

@media screen and (max-width: 400px) {
  .article {
    max-width: 200px;
    min-width: 200px !important;
  }
}

@media screen and (max-width: 500px) {
  .article {
    max-width: 100%;
    min-width: 300px !important;
  }
}

.article:hover h3,
.article:hover p {
  text-decoration: underline;
}

.imageContainer,
.imagePlaceholder {
  position: relative;
  aspect-ratio: 16 / 9;
}

.imagePlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.imagePlaceholder svg {
  width: 60%;
  height: 60%;
  transform: rotate(-25deg);
  opacity: 0.25;
}

@media screen and (max-width: 500px) {
  .imageContainer {
    max-height: 200px;
  }
}

.imageContainer span {
  position: static !important;
}

.imageContainer img {
  object-fit: cover;
}

.date {
  font-size: var(--font-size-details) !important;
  font-weight: var(--font-weight-regular);
}

.article a:first-of-type {
  z-index: 2;
}

.cardLink {
  color: transparent;
  height: 0;
  width: 0;
}

.cardLink:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
